<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="5">
        <v-card>
          <v-card-title>Datos Personales</v-card-title>

          <v-card-text>
            <p> <b>Nombre:</b> {{ customer.name }} </p>

            <p> <b>Email:</b> {{ customer.email }} </p>
          </v-card-text>
        </v-card>

        <v-card class="mt-3">
          <v-card-title>Dirección</v-card-title>

          <v-card-text>
            <p> <b>Calle:</b> {{ address.street }} </p>

            <p> <b>Número exterior:</b> {{ address.outer_number }} </p>

            <p> <b>Número interior:</b> {{ address.inner_number || '---' }} </p>

            <p> <b>Colonia:</b> {{ address.suburb }} </p>

            <p> <b>Municipio/Alcaldía:</b> {{ address.municipality }} </p>

            <p> <b>Ciudad:</b> {{ address.city }} </p>

            <p> <b>Estado:</b> {{ address.state }} </p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="d-flex justify-center text-center" style="word-break: normal;">
                Fecha y hora de recolección
              </v-card-title>

              <v-card-text class="text-center">
                <b>Fecha:</b> {{ $store.state.signup.date | date }}
                <br />
                <b>Hora Inicio:</b> {{ $store.state.signup.time_start }} hrs
                <br />
                <b>Hora Fin:</b> {{ $store.state.signup.time_end }} hrs
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <template v-if="$store.state.signup.individualSelection">
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Servicio</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="item in $store.state.signup.products" :key="item.product.uuid">
                      <td>{{ item.product.name }}</td>
                      <td class="text-right">
                        {{ item.product.unit_price_normal * item.quantity | currency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text class="text-center">
                <b class="mr-1">TOTAL A PAGAR:</b>

                <template v-if="$store.state.signup.individualSelection">
                  <b>
                    {{ $store.getters['signup/total'] | currency }}
                  </b>

                  <div v-if="$store.state.signup.discount" class="green--text">
                    Descuento del <b>{{ $store.state.signup.discount.discount }}%</b> aplicado
                    usando el cupón <b>{{ $store.state.signup.discount.code }}</b>
                  </div>
                </template>

                <template v-else>
                  <b>$ 35.00</b>
                  <br />

                  <small style="line-height: 1">
                    Estos $35 pesos son a cuenta de tu nota.
                    <br />
                    En caso de cancelar la cita antes de 24 horas se tomarán para tu siguiente
                    pedido.
                    <br />
                    En caso de hacer una visita en falso (no cancelar o cambiar la cita 24 hrs
                    previo) se tomará como pena.
                  </small>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn color="success" @click="showPayment"> Pagar </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Modal
      type="primary"
      :cancelable="false"
      :show.sync="showConfirmationModal"
      @submit="redirectToLogin"
    >
      <template #title>Orden completada</template>

      <template #body>
        Tu orden ha sido creada exitósamente.
        <br /><br />
        <b>Número de orden: {{ orderNumber }}</b>
      </template>

      <template #action>Ir a inicio de sesión</template>
    </Modal>

    <Payment
      :show.sync="showPaymentModal"
      :amount="$store.getters['signup/total']"
      @save="saveOrder"
    />
  </v-container>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// API
import OrderAPI from '@/api/order';

// Components
import Modal from '@/components/modal/index.vue';
import Payment from '@/components/payment/index.vue';

export default {
  name: 'StepFour',

  components: {
    Modal,
    Payment,
  },

  data: () => ({
    orderNumber: null,
    showConfirmationModal: false,

    // Payment modal
    showPaymentModal: false,
    total: 0,
  }),

  computed: {
    ...mapState('signup', ['customer', 'address', 'step']),
  },

  async created() {
    this.api = new OrderAPI();
  },

  methods: {
    async saveOrder() {
      this.$store.commit('showLoader');
      try {
        let paid = false;
        let pickup = false;
        let paidPickup = false;

        if (this.$store.state.signup.individualSelection) {
          paid = true;
        } else {
          pickup = true;
          paidPickup = true;
        }

        const { number } = await this.api.create({
          customer_id: this.$store.state.signup.customer.uuid,
          date: this.$store.state.signup.date,
          discount: this.$store.state.signup.discount,
          items: this.$store.state.signup.products,
          office_uuid: this.$store.state.signup.office.uuid,
          time_end: this.$store.state.signup.time_end,
          time_start: this.$store.state.signup.time_start,
          paid_pickup: paidPickup,
          paid,
          pickup,
        });

        this.$store.dispatch('notification/notifySuccess');
        this.orderNumber = number;
        this.showConfirmationModal = true;
      } catch (error) {
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    getTotalItems() {
      let total = this.$store.state.signup.products.reduce(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (sum, item) => (sum += item.product.unit_price_normal * item.quantity),
        0,
      );

      if (!this.$store.state.signup.individualSelection) {
        total = 35;
      }

      return total;
    },

    showPayment() {
      this.total = this.getTotalItems();
      this.showPaymentModal = true;
    },

    redirectToLogin() {
      this.$store.commit('signup/reset');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
