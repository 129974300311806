<template>
  <v-stepper v-model="step" alt-labels>
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1"> General </v-stepper-step>

      <v-divider />

      <v-stepper-step step="2" :complete="step > 2"> Sucursal </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3" :complete="step > 3"> Servicios </v-stepper-step>

      <v-divider />

      <v-stepper-step step="4" :complete="step > 4"> Pago </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepOne />
      </v-stepper-content>

      <v-stepper-content step="2">
        <StepTwo />
      </v-stepper-content>

      <v-stepper-content step="3">
        <StepThree />
      </v-stepper-content>

      <v-stepper-content step="4">
        <StepFour />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
// Components
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue';
import StepFour from './StepFour.vue';

export default {
  name: 'Steps',

  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
  },

  computed: {
    step: {
      get() {
        return this.$store.state.signup.step;
      },

      set(v) {
        this.$store.commit('signup/setStep', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
