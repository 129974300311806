<template>
  <Form @submit="saveCustomer">
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <TextField
          v-model="form.name"
          label="Nombre"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <TextField
          v-model="form.email"
          label="Correo electrónico"
          prepend-icon="mdi-at"
          rules="required|email"
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <TextField
          v-model="form.password"
          label="Contraseña"
          prepend-icon="mdi-key"
          rules="required"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.mobile_phone"
          counter="12"
          label="Celular"
          maxlength="12"
          prepend-icon="mdi-phone"
          rules="required|min:10|max:12"
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <TextField
          v-model="form.postal_code"
          counter="5"
          label="Código postal"
          maxlength="5"
          prepend-icon="mdi-google-maps"
          rules="required|max:5|min:5"
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <v-checkbox v-model="noticeOfPrivacy">
          <template v-slot:label>
            <div>
              Acepto el
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    :href="$router.resolve({ name: 'notice-of-privacy' }).href"
                    v-on="on"
                    @click.stop
                  >
                    aviso de privacidad
                  </a>
                </template>
                Abrir
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn
          color="primary"
          type="submit"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
// API
import CustomerAPI from '@/api/customer';

export default {
  name: 'StepOne',

  data: () => ({
    form: {
      email: null,
      mobile_phone: null,
      name: null,
      password: null,
      postal_code: null,
    },
    noticeOfPrivacy: false,
    showPassword: false,
  }),

  created() {
    this.api = new CustomerAPI();
  },

  methods: {
    async saveCustomer() {
      if (!this.noticeOfPrivacy) {
        this.$store.dispatch('notification/notifyError', 'Debes aceptar el aviso de privacidad');
        return;
      }

      this.$store.commit('showLoader');
      try {
        const { uuid } = await this.api.signup(this.form);
        const customer = {
          ...this.form,
          uuid,
        };
        this.$store.commit('signup/setCustomer', customer);
        this.$store.commit('signup/nextStep');
      } catch (error) {
        if (error.data.email?.pop() === 'Person with this Email already exists.') {
          this.$store.dispatch('notification/notifyError', 'Este email ya está registrado');
        } else {
          this.$store.dispatch('notification/notifyError');
        }
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
