<template>
  <v-container class="text-center">
    <v-row
      v-if="$store.state.signup.offices.length"
      justify="center"
    >
      <v-col cols="12">
        Selecciona la sucursal de tu preferencia.
      </v-col>

      <v-col
        v-for="office in $store.state.signup.offices"
        :key="office.uuid"
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card>
          <div class="d-flex flex-column-reverse flex-sm-row justify-space-between">
            <div>
              <v-card-title class="justify-center">
                {{ office.name }}
              </v-card-title>

              <v-card-text>
                {{ office.address.full_address }}
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  color="primary"
                  :disabled="$store.state.signup.office.name === office.name"
                  text
                  @click="$store.commit('signup/setOffice', office)"
                >
                  {{
                    $store.state.signup.office.name === office.name
                      ? 'Seleccionada'
                      : 'Escoger'
                  }}
                </v-btn>
              </v-card-actions>
            </div>

            <div>
              <v-avatar size="150">
                <v-img :src="office.logo" />
              </v-avatar>

              <div class="d-flex justify-center">
                <img
                  v-for="service in office.services"
                  :key="service.id"
                  :src="getServiceIcon(service.icon)"
                  class="service-icon"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-icon color="error" x-large>mdi-cancel</v-icon>
      </v-col>

      <v-col cols="12">
        <b>
          Por el momento no contamos con cobertura dentro de tu zona,
          pero te informaremos en cuanto una sucursal esté lista para atenderte.
        </b>
      </v-col>
    </v-row>

    <template v-if="$store.state.signup.office.name">
      <v-divider class="my-3" />

      <v-row>
        <v-col cols="12">
          Por favor, completa tu dirección.
        </v-col>
      </v-row>

      <Form @submit="saveAddress">
        <AddressForm :address="$store.state.signup.address" />

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </Form>
    </template>
  </v-container>
</template>

<script>
// API
import CustomerAPI from '@/api/customer';
import OfficeAPI from '@/api/office';

// Components
import AddressForm from '@/components/address/Form.vue';

// Utils
import { getServiceIcon } from '@/utils/service';

export default {
  name: 'StepTwo',

  components: {
    AddressForm,
  },

  computed: {
    step() {
      return this.$store.state.signup.step;
    },
  },

  watch: {
    async step() {
      // Get offices only if user is in the current step
      // and has not selected any office yet.
      if (this.step === 2 && !this.$store.state.signup.office.name) {
        await this.availableOffices();
      }
    },
  },

  created() {
    this.officeAPI = new OfficeAPI();
    this.customerAPI = new CustomerAPI();
  },

  methods: {
    getServiceIcon,

    async availableOffices() {
      this.$store.commit('showLoader');
      try {
        const offices = await this.officeAPI.availableOffices({
          postal_code: this.$store.state.signup.customer.postal_code,
        });
        this.$store.commit('signup/setOffices', offices);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async saveAddress() {
      this.$store.commit('showLoader');
      try {
        await this.customerAPI.address(
          this.$store.state.signup.customer.uuid,
          this.$store.state.signup.address,
        );
        await this.customerAPI.setOffice(
          this.$store.state.signup.customer.uuid,
          { office_uuid: this.$store.state.signup.office.uuid },
        );
        this.$store.commit('signup/nextStep');
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
