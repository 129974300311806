<template>
  <Form @submit="$store.commit('signup/nextStep')">
    <v-container class="text-center">
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <v-radio-group v-model="$store.state.signup.individualSelection" row>
            <v-radio label="Cita para recolección" :value="false" />
            <v-radio label="Arma tu pedido" :value="true" />
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <TextField
            v-model="$store.state.signup.date"
            label="Fecha"
            type="date"
            prepend-icon="mdi-calendar"
            rules="required"
            :min="currentDate"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <TextField
            v-model="$store.state.signup.time_start"
            label="Hora Inicio *"
            type="time"
            prepend-icon="mdi-clock-outline"
            rules="required|schedule"
            min="09:00"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <TextField
            v-model="$store.state.signup.time_end"
            label="Hora Fin *"
            type="time"
            prepend-icon="mdi-clock-outline"
            rules="required|schedule"
            max="20:00"
          />
        </v-col>
      </v-row>

      <template v-if="$store.state.signup.individualSelection">
        <v-row>
          <v-col cols="12"> Agrega los servicios que necesites. </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-data-iterator :items="$store.state.signup.products" hide-default-footer>
              <template v-slot:no-data> No has seleccionado ningún servicio. </template>

              <template v-slot:header>
                <v-row justify="center">
                  <v-col class="d-flex flex-column flex-md-row align-md-center" cols="12">
                    <SelectField
                      v-model="serviceId"
                      :items="services"
                      class="mr-md-5"
                      item-text="name"
                      item-value="id"
                      label="Familia"
                      prepend-icon="mdi-format-list-bulleted"
                      @change="getProducts"
                    />

                    <v-autocomplete
                      v-model="form.product"
                      :items="products"
                      color="white"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      item-value="uuid"
                      prepend-icon="mdi-hanger"
                      :disabled="disableProductsCombo"
                      :placeholder="productsPlaceholder"
                      return-object
                    />

                    <v-btn class="mx-auto" color="error" fab small @click="addProduct">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:default="props">
                <v-row>
                  <v-col v-for="item in props.items" :key="item.product.uuid" cols="12" md="6">
                    <v-card>
                      <v-card-subtitle>
                        <div class="d-flex justify-space-between">
                          <span>{{ item.product.name }}</span>

                          <span>
                            {{ item.product.unit_price_normal * item.quantity | currency }}
                          </span>
                        </div>

                        <v-divider class="my-3" />

                        <div class="d-flex justify-space-between">
                          <v-btn color="error" icon x-small @click="removeProduct(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>

                          <div>
                            <v-btn color="primary" icon x-small @click="updateQuantity(item, -1)">
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>

                            <span class="mx-3">
                              {{ item.quantity }}
                            </span>

                            <v-btn color="primary" icon x-small @click="updateQuantity(item, +1)">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:footer>
                <v-card class="mt-5">
                  <v-card-text class="text-center">
                    <b class="text-h5">TOTAL: {{ $store.getters['signup/total'] | currency }}</b>

                    <v-row class="mt-4" justify="center">
                      <v-col class="d-flex pa-0" cols="12" sm="6" lg="3">
                        <TextField
                          class="flex-grow-1"
                          maxlength="15"
                          label="Código de descuento"
                          prepend-icon="mdi-ticket"
                          rules=""
                          :value="discount_code"
                          @input="discount_code = $event.toUpperCase()"
                        />
                        <v-btn color="primary" @click="applyDiscount"> Aplicar </v-btn>
                      </v-col>

                      <v-col class="pa-0" cols="12">
                        <span :class="discount_alert.type">
                          {{ discount_alert.message }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
      </template>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" type="submit" :disabled="disableSubmit"> Continuar </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </Form>
</template>

<script>
// Libraries
import moment from 'moment';

// API
import DiscountAPI from '@/api/discount';
import OfficeAPI from '@/api/office';
import ProductAPI from '@/api/product';

const initialForm = () => ({
  quantity: 1,
  product: null,
});

export default {
  name: 'StepThree',

  data: () => ({
    discount_alert: {
      message: null,
      type: 'red--text',
    },
    discount_code: null,
    discount: null,
    currentDate: moment().format('YYYY-MM-DD'),
    form: initialForm(),
    products: [],
    services: [],
    serviceId: null,
  }),

  computed: {
    disableProductsCombo() {
      return !this.products.length;
    },

    disableSubmit() {
      const collectionDateTime =
        this.$store.state.signup.date &&
        this.$store.state.signup.time_start &&
        this.$store.state.signup.time_end;

      return this.$store.state.signup.individualSelection
        ? !collectionDateTime || !this.$store.state.signup.products.length
        : !collectionDateTime;
    },

    productsPlaceholder() {
      return this.disableProductsCombo ? 'No hay servicios' : 'Busca un servicio';
    },

    step() {
      return this.$store.state.signup.step;
    },
  },

  watch: {
    '$store.state.signup.individualSelection': function () {
      this.$store.commit('signup/removeAllProducts');
    },
  },

  async created() {
    this.api = new OfficeAPI();
    this.productAPI = new ProductAPI();
    this.discountAPI = new DiscountAPI();
    await this.getServices();
  },

  methods: {
    async getServices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.productAPI.services();
        this.services = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getProducts() {
      this.$store.commit('showLoader');
      try {
        this.products = await this.api.products(this.$store.state.signup.office.uuid, {
          service_id: this.serviceId,
        });
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    addProduct() {
      if (!this.form.product) return;

      this.$store.commit('signup/addProduct', this.form);
      this.form = initialForm();
    },

    removeProduct(product) {
      this.$store.commit('signup/removeProduct', product);
    },

    updateQuantity(item, operation) {
      this.$store.commit('signup/updateQuantity', { item, operation });
    },

    async applyDiscount() {
      this.$store.commit('showLoader');
      try {
        this.$store.commit('signup/setDiscount', null);
        this.discount = null;
        this.discount = await this.discountAPI.getByCode({
          code: this.discount_code,
          office: this.$store.state.signup.office.uuid,
        });
        this.discount_alert = {
          message: `Descuento del ${this.discount.discount}% aplicado.`,
          type: 'green--text',
        };
        this.$store.commit('signup/setDiscount', this.discount);
      } catch (error) {
        // console.log(error);
        this.discount_alert = {
          message: error.data.message,
          type: 'red--text',
        };
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quantity {
  input {
    text-align: center;
  }

  .v-label {
    left: calc(50% - 1.3em) !important;
  }
}

::v-deep .toolbar-total .v-toolbar__content {
  justify-content: center;
}
</style>
