<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
    >
      <v-toolbar-title>
        <v-btn href="https://www.wapidu.com">
          Wapidu
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'login' }">
        Iniciar Sesión
      </v-btn>
    </v-app-bar>

    <v-main>
      <Notification />

      <v-container>
        <v-row justify="center">
          <v-col
            class="text-center"
            cols="12"
            lg="8"
          >
            <a href="https://www.wapidu.com">
              <v-img src="@/assets/img/logo_color.png" />
            </a>

            <h1 class="mt-5">Registro</h1>
          </v-col>

          <v-col cols="12">
            <Steps />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      Wapidu 2020 &copy;
    </v-footer>
  </v-app>
</template>

<script>
// Components
import Notification from '@/components/notification/index.vue';
import Steps from './components/Steps.vue';

export default {
  name: 'Signup',

  components: {
    Notification,
    Steps,
  },
};
</script>

<style lang="scss" scoped>

</style>
